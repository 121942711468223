// https://next-intl-docs.vercel.app/docs/usage/configuration#default-translation-values

export default {
  AUM_THRESHOLD_PREMIUM: 100_000,
  AUM_THRESHOLD_CORE_ALTS: 50_000,
  MANAGED_INVEST_FEE_CORE: 0.005,
  MANAGED_INVEST_FEE_PREMIUM: 0.004,
  TRANSFER_MIN_VALUE_FOR_REIMBURSEMENT: 15_000,
  TRANSFER_MATCH_BONUS: 0.01,
};
